import { MenuFoldOutlined, MenuUnfoldOutlined,UserOutlined,LoginOutlined } from '@ant-design/icons';
import { Avatar } from 'antd';
import {
  ProLayout,
} from '@ant-design/pro-components';
import {
  // ChromeFilled,
  CrownFilled,
  SmileFilled,
  TabletFilled,
} from '@ant-design/icons';
import React,{ useState, Suspense, useEffect } from 'react';
import { Route, Routes, Navigate, useNavigate } from "react-router-dom"
import "./index.scss"
import {$adminCurrentUser} from "../api/index";
const Login = React.lazy(() => import('../pages/login/index'))
const Home = React.lazy(() => import('../pages/home/index'))
const CreateChannel = React.lazy(() => import('../pages/create/channel/index'))
const CreateService = React.lazy(() => import('../pages/create/service/index'))
const CreateAgent = React.lazy(() => import('../pages/create/agent/index'))
const CreateAgentUser = React.lazy(() => import('../pages/create/agent/user'))
const ListChannel = React.lazy(() => import('../pages/list/channel/index'))
const ListOrder = React.lazy(() => import('../pages/list/order/index'))
const ListComplaint = React.lazy(() => import('../pages/list/complaint/index'))
const ListAgent = React.lazy(() => import('../pages/list/agent/index'))
const ListAgentUser = React.lazy(() => import('../pages/list/agent/user'))
const ListService = React.lazy(() => import('../pages/list/service/index'))
const BuyMore = React.lazy(() => import('../pages/configuration/buyMore/index'))
const PageGlobal = React.lazy(() => import('../pages/configuration/pageGlobal/index'))


function Layout () { //登陆成功

  return (
      <Routes>
        <Route path="/login" element={<Suspense><Login /></Suspense>} />
        <Route path="*" element={<Suspense><LandSuccessfully /></Suspense>}></Route>
      </Routes>
  )
}

function LandSuccessfully() {
  const navigate = useNavigate();
  const [isPage,getIsPage] = useState(false)
  const [pathname, setPathname] = useState(window.location.pathname);
  const [titleName, setTitleName] = useState('创建渠道号');
  const [collapsed, setCollapsed] = useState(true);
  const [userInfo,setUserInfo] = useState(null)
  const [defaultProps] = useState({
    route: {
      path: '/',
      routes: [
        {
          path: '/home',
          name: '首页',
          icon: <SmileFilled />,
          component: './Welcome',
        },
        {
          path: '/create',
          name: '新建',
          icon: <CrownFilled />,
          access: 'canAdmin',
          component: './create',
          routes: [
            {
              path: '/create/channel',
              name: '创建渠道号',
              icon: 'https://gw.alipayobjects.com/zos/antfincdn/upvrAjAPQX/Logo_Tech%252520UI.svg',
              component: './create/channel',
            },
            {
              path: '/create/service',
              name: '创建微信客服',
              icon: 'https://gw.alipayobjects.com/zos/antfincdn/upvrAjAPQX/Logo_Tech%252520UI.svg',
              component: './create/service',
            },
            {
              path: '/create/agent/menu',
              name: '新建代理商',
              icon: 'https://gw.alipayobjects.com/zos/antfincdn/upvrAjAPQX/Logo_Tech%252520UI.svg',
              component: './create/agent/menu',
              routes: [
                {
                  path: '/create/agent/index',
                  name: '新建代理商',
                  icon: 'https://gw.alipayobjects.com/zos/antfincdn/upvrAjAPQX/Logo_Tech%252520UI.svg',
                  component: './create/agent/index',
                },
                {
                  path: '/create/agent/user',
                  name: '新建用户',
                  icon: 'https://gw.alipayobjects.com/zos/antfincdn/upvrAjAPQX/Logo_Tech%252520UI.svg',
                  component: './create/agent/user',
                },
              ],
            },
          ],
        },
        {
          path: '/list',
          name: '列表页',
          icon: <TabletFilled />,
          component: './ListTableList',
          routes: [
            {
              path: '/list/channel',
              name: '渠道号列表',
              icon: <CrownFilled />,
              component: './list/channel',
            },
            {
              path: '/list/order',
              name: '订单列表',
              icon: <CrownFilled />,
              component: './list/order',
            },
            {
              path: '/list/complaint',
              name: '投诉列表',
              icon: <CrownFilled />,
              component: './list/complaint',
            },
            {
              path: '/list/service',
              name: '微信客服列表',
              icon: <CrownFilled />,
              component: './list/service',
            },
            {
              path: '/list/agent/menu',
              name: '代理商',
              icon: <CrownFilled />,
              component: './list/agent/menu',
              routes: [
                {
                  path: '/list/agent/index',
                  name: '代理商列表',
                  icon: 'https://gw.alipayobjects.com/zos/antfincdn/upvrAjAPQX/Logo_Tech%252520UI.svg',
                  component: './list/agent/index',
                },
                {
                  path: '/list/agent/user',
                  name: '用户列表',
                  icon: 'https://gw.alipayobjects.com/zos/antfincdn/upvrAjAPQX/Logo_Tech%252520UI.svg',
                  component: './list/agent/user',
                },
              ],
            },
          ],
        },
        {
          path: '/configuration',
          name: '配置',
          icon: <CrownFilled />,
          access: 'canAdmin',
          component: './configuration',
          routes: [
            {
              path: '/configuration/buyMore',
              name: '结果页增购配置',
              icon: 'https://gw.alipayobjects.com/zos/antfincdn/upvrAjAPQX/Logo_Tech%252520UI.svg',
              component: './configuration/buyMore',
            },
            {
              path: '/configuration/PageGlobal',
              name: '全局配置',
              icon: 'https://gw.alipayobjects.com/zos/antfincdn/upvrAjAPQX/Logo_Tech%252520UI.svg',
              component: './configuration/PageGlobal',
            },
          ],
        },
     
        // {
        //   path: 'https://ant.design',
        //   name: 'Ant Design 官网外链',
        //   icon: <ChromeFilled />,
        // },
      ],
    },
    location: {
      pathname: '/',
    },
    
  })
  
  const props = {
    ...defaultProps,
    location: {
      pathname,
    },
    collapsed,
    fixSiderbar: true,
    collapsedButtonRender: false,
    menuItemRender: (item, dom) => (
      <div
        onClick={() => {
        // console.log('点击菜单',item,dom)
          navigate(item.path);
          setTitleName(item.name)
          setPathname(item.path);
        }}
      >
        {dom}
      </div>
    ),
  };
  useEffect(()=>{
    $adminCurrentUser().then((res)=>{
      if ( res.status === 200 ) {
        getIsPage(true)
        //setUserInfo
        setUserInfo(res.data)
        // console.log('res',res.data)
      }
    })
  },[])
  function hideMenuItem(menuData, pathToHide) {
    // 隐藏菜单项的函数
    return menuData.map((item) => {
      if (item.path === pathToHide) {
        return null; // 返回null代表隐藏该菜单项
      }
      if (item.routes) {
        item.routes = hideMenuItem(item.routes, pathToHide);
      }
      return item;
    }).filter(Boolean); // 使用filter(Boolean)来移除null项
  }
  const hiddenMenuData = () => {
    //通过权限禁用部分菜单
    let concealData = hideMenuItem(defaultProps.route.routes, '')
    if (userInfo.role === "com") {
      concealData = hideMenuItem(defaultProps.route.routes, '/list/agent/menu')
      concealData = hideMenuItem(defaultProps.route.routes, '/list/complaint')
      concealData = hideMenuItem(defaultProps.route.routes, '/list/service')
      concealData = hideMenuItem(defaultProps.route.routes, '/create/service')
      concealData = hideMenuItem(defaultProps.route.routes, '/create/agent/menu')
      concealData = hideMenuItem(defaultProps.route.routes, '/configuration')
    }
    return concealData
  };
  function exitLoginButton () {
    window.localStorage.removeItem('tokenKey')
    navigate("/login");
  }
  return (
      <div className="layout-chunk">
        {isPage?
          <ProLayout
              menuDataRender={() => hiddenMenuData()}
              {...props}
              layout="mix"
              title={userInfo.name}
              // loading={true}
              onCollapse={setCollapsed}
              // headerRender={()=>{
              //   return (
              //       <div className='big-title-name'>{titleName}</div>
              //   )
              // }}
              logo={()=>{
                return (
                  <div className='user-chunk'>
                    <Avatar icon={<UserOutlined />} />
                    <div className='exit-button' onClick={exitLoginButton}><LoginOutlined style={{marginRight:'10px'}} />退出登陆</div>
                  </div>
                )
              }}
              postMenuData={(menuData) => {
                return [
                  {
                    icon: collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />,
                    name: titleName,
                    itemPath:pathname,
                    onTitleClick: () => {
                      setPathname(pathname);
                      return setCollapsed(!collapsed)

                    },
                  },
                  ...(menuData || []),
                ];
              }}
          >
            <Routes>
              <Route path="/" element={<Navigate to="/home" />}></Route>
              <Route path="/home" element={ <Suspense><Home /></Suspense>} />

              <Route path="/create" element={<Navigate to="/create/channel" />}></Route>
              <Route path="/create">
                <Route path="channel" element={<Suspense><CreateChannel userInfo={userInfo} /></Suspense> }/>
                <Route path="service" element={<Suspense><CreateService userInfo={userInfo} /></Suspense> }/>
                <Route path="agent">
                  <Route path="index" element={<Suspense><CreateAgent/></Suspense> }/>
                  <Route path="user" element={<Suspense><CreateAgentUser role={userInfo.role}/></Suspense> }/>
                </Route>
              </Route>

              <Route path="/list" element={<Navigate to="/list/channel" />}></Route>
              <Route path="/list">
                <Route path="channel" element={<Suspense><ListChannel userInfo={userInfo}/></Suspense>}/>
                <Route path="channel/details/:id" element={<Suspense><CreateChannel userInfo={userInfo}/></Suspense>}/>
                <Route path="service" element={<Suspense><ListService userInfo={userInfo}/></Suspense>}/>
                <Route path="service/details/:id" element={<Suspense><CreateService userInfo={userInfo}/></Suspense>}/>
                <Route path="order" element={<Suspense><ListOrder/></Suspense>}/>
                <Route path="complaint" element={<Suspense><ListComplaint/></Suspense>}/>
                <Route path="agent/details/:id" element={<Suspense><CreateAgent/></Suspense>}/>
                <Route path="agent/user/details/:id" element={<Suspense><CreateAgentUser/></Suspense>}/>
                <Route path="agent">
                  <Route path="index" element={<Suspense><ListAgent/></Suspense> }/>
                  <Route path="user" element={<Suspense><ListAgentUser/></Suspense> }/>
                </Route>
              </Route>

              <Route path="/configuration" element={<Navigate to="/configuration/buyMore" />}></Route>
              <Route path="/configuration">
                <Route path="buyMore" element={<Suspense><BuyMore/></Suspense>}/>
                <Route path="PageGlobal" element={<Suspense><PageGlobal/></Suspense>}/>
              </Route>
 

            </Routes>
          </ProLayout>
          :''
        }
      </div>
  );
}

export default Layout;
